
@font-face {
  font-family: NotoSansJP-Black;
  src: url('../public/assets/NotoSansJP-Black.otf');
}
@font-face {
  font-family: NotoSansJP-Light;
  src: url('../public/assets/NotoSansJP-Light.otf');
}
@font-face {
  font-family: NotoSansJP-Bold;
  src: url('../public/assets/NotoSansJP-Bold.otf');
}
@font-face {
  font-family: NotoSansJP-Medium;
  src: url('../public/assets/NotoSansJP-Medium.otf');
}
@font-face {
  font-family: NotoSansJP-Regular;
  src: url('../public/assets/NotoSansJP-Regular.otf');
}

@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  width: 200px; /* Set a fixed width for the button */
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #6415ff;
  color: white;
  overflow: hidden;
  height: 50px; /* Adjust height to fit the text */
}
.button:hover {
  border: 2px solid #6415ff ;
  background-color: white;
  color: #6415ff;
}

.button .span1 {
  position: absolute;
  left: 35%;
  bottom: 50%;
  transform: translate(0%, 50%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.button .span1_summary {
  position: absolute;
  left: 15%;
  bottom: 50%;
  transform: translate(0%, 50%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.button .span2 {
  position: absolute;
  /* left: 40%; */
  bottom: -10%;
  transform: translate(0%, 100px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.button .span2_center {
  position: absolute;
  left: 35%;
}


.up {
  animation: fadeUp 0.3s forwards;
}

.down {
  animation: fadeDown 0.3s forwards;
}

/* styles.css */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeOut 1s ease-in-out forwards;
  animation-delay: 3s; /* Delay for how long the splash screen stays visible */
}

.splash-screen .logo {
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 1s; /* Delay for the logo to start fading in */
}

